<template>
  <div>
    <div class="backgroundOV"></div>
    <div>
      <v-card outlined>
        <v-card-title class="titleLarge"
          >Einspluseins – Übergang Diagnose</v-card-title
        >
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller"
          >Welche Facetten des Hintergrundwissens konnten beim Einspluseins
          ausgemacht werden?
        </v-card-title>
        <v-card-text>
          Das Hintergrundwissen im Bereich Einspluseins lässt sich aufgliedern
          in die drei Facetten:
          <ul>
            <li>Grundvorstellungen der Addition</li>
            <li>Nutzen von Aufgabenbeziehungen</li>
            <li>Darstellungen vernetzen</li>
          </ul>
          Das Wissen um diese drei Teilbereiche ist vor allem auf für die
          Diagnose und Förderung der Kinder wichtig, um zum einen
          diagnostizieren zu können, wo genau beispielsweise Probleme auftreten
          und daran anschließend passgenaue Förderung anzustreben.
        </v-card-text>
      </v-card>
      <br />
      <br />
      <v-card outlined>
        <v-card-title class="titleSmaller"
          >Was erwartet Sie im Bereich Diagnose und Förderung im Einspluseins?
        </v-card-title>
        <v-card-text>
          In mehreren Aufgaben betrachten Sie gleich Lösungen von Kindern, um
          <ul>
            <li>typische Fehler im Bereich des Einspluseins zu gruppieren,</li>
            <li>Fehler im Bereich des Einspluseins zu diagnostizieren und</li>
            <li>Förderaufgaben für Kinder diagnosegeleitet auszuwählen.</li>
          </ul>
        </v-card-text>
      </v-card>
    </div>
    <AppBottomNavEPE
      next="/einspluseins/diagnose-und-foerderung"
      back="/einspluseins/hintergrundwissen/darstellungen"
    />
  </div>
</template>

<script>
import AppBottomNavEPE from "@/common/AppBottomNavEPE";
export default {
  components: {
    AppBottomNavEPE,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}
#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
